import "./FooterPublicTwo.scss";
import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import logoFooter from "../../../styles/images/silla-naranja.svg";
import Icon from "../../Icon/Icon";
import InputText from "../../DesingSystem/InputText/InputText";
import jsonp from "jsonp";
import CheckBox from "../../DesingSystem/CheckBox/CheckBox";

function FooterPublic() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [subscribeMessage, setSubscribeMessage] = useState("");
  const [subscribeErrorMessage, setSubscribeErrorMessage] = useState("");
  const [isEmailEntered, setIsEmailEntered] = useState(false);

  const handleInputEmailFocus = () => {
    setIsEmailEntered(true);
  };
  const handleInputEmailBlur = () => {
    setIsEmailEntered(false);
  };

  const navigateToPath = (path) => {
    navigate(path);
  };

  const goToFb = () => {
    navigate("");
  };
  const goToIg = () => {
    navigate("");
  };
  const goToLi = () => {
    navigate("");
  };
  const goToTw = () => {
    navigate("");
  };

  const subscribeMC = () => {
    const url =
      "https://gmail.us21.list-manage.com/subscribe/post-json?u=edc817b300044842c360db178&id=96ce411c34&v_id=74&f_id=00c389e1f0";
    jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (_, { msg, result }) => {
      if (result === "error") {
        setSubscribeErrorMessage(msg.replace("0 -", ""));
        setSubscribeMessage("");
      } else {
        setSubscribeMessage(msg);
        setSubscribeErrorMessage("");
      }
    });
  };

  return (
    <>
      <section className="footer-public-two">
        <div id="logo" className="footer-public-two__section-logo">
          <img src={logoFooter} alt="Logo Bookolicos text"></img>
        </div>

        <div id="rrss" className="footer-public-two__section-rsss">
          <ul className="footer-public-two__section-rsss-list">
            {/*<li className="footer-public-two__section-rsss-list-item">
              <a href="#" target="_blank">
                <Icon
                  name="facebook"
                  size="medium2"
                  color="secondary-6"
                  background="4"
                />
              </a>
            </li>*/}
            <li
              className="footer-public-two__section-rsss-list-item"
            >
              <a href="https://instagram.com/bookolicos.es" target="_blank">
                <Icon
                  name="instagram"
                  size="medium2"
                  color="secondary-6"
                  background="4"
                />
              </a>
            </li>
            <li
              className="footer-public-two__section-rsss-list-item"
            >
              <a href="https://www.linkedin.com/company/bookólicos/" target="_blank">
                <Icon
                  name="linkedin"
                  size="medium2"
                  color="secondary-6"
                  background="4"
                />
              </a>
            </li>
            {/*<li className="footer-public-two__section-rsss-list-item">
              <a href="#" target="_blank">
                <Icon
                  name="twitter"
                  size="medium2"
                  color="secondary-6"
                  background="4"
                />
              </a>
            </li>*/}
          </ul>
        </div>

        <div id="legality" className="footer-public-two__section-legality">
          <Link to="/politica-privacidad" target="_blank">
            <p className="footer-public-two__section-legality-text">
              Política de privacidad
            </p>
          </Link>
          <Link to="/aviso-legal" target="_blank">
            <p className="footer-public-two__section-legality-text">Aviso legal</p>
          </Link>
          <Link to="/condiciones-generales" target="_blank">
            <p className="footer-public-two__section-legality-text">
              Condiciones de compra
            </p>
          </Link>
          <Link to="/terminos-condiciones" target="_blank">
            <p className="footer-public-two__section-legality-text">
              Términos y condiciones de uso
            </p>
          </Link>
        </div>

        <div id="input" className="footer-public-two__section-input">
          <div className="footer-public__section-input-up">
            <p
              className="footer-public-two__section-input-text"
              onClick={() => navigateToPath("/descubre")}
            >
              Catálogo
            </p>
            <p className="footer-public-two__section-input-text" onClick={() => navigateToPath("/por-que-bookolicos")}>
              ¿Por qué Bookólicos?
            </p>
            <p className="footer-public-two__section-input-text" onClick={() => navigateToPath("/quienes-somos")}>
              Quiénes somos
            </p>
          </div>
          {/*<p className="footer__section-input-text" onClick={gotToNews}>
              Noticias{" "}
              </p>*/}
          <div className="footer-public-two__section-input-down">
            <p
              className="footer-public__section-input-text"
              onClick={() => navigateToPath("/noticias")}
            >
              Blog
            </p>
            <p
              className="footer-public-two__section-input-text"
              onClick={() => navigateToPath("/faqs")}
            >
              FAQs
            </p>
            <p
              className="footer-public-two__section-input-text"
              onClick={() => navigateToPath("/contacto")}
            >
              Contacto
            </p>
          </div>
        </div>

        <div id="newsletter" className="footer-public-two__section-newsletter">
          <div className="footer-public-two__section-newsletter-input">
            <p className="footer-public-two__section-newsletter-text">
              Suscríbete a la Newsletter de Bookólicos
            </p>
            <InputText
              icon="arrowDiagonal"
              iconSize="large"
              iconColor="secondary-6"
              iconBorder="background-5"
              error={isEmailEntered}
              onFocus={handleInputEmailFocus}
              onBlur={handleInputEmailBlur}
              type="email"
              id="email"
              name="email"
              placeholder="Escribe tu email"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              iconClick={() => {
                subscribeMC();
              }}
            />

            {subscribeMessage && subscribeMessage !== "" ? (
              <p className="success ms-3">{subscribeMessage}</p>
            ) : null}

            {subscribeErrorMessage && subscribeErrorMessage !== "" ? (
              <p className="error ms-3">{subscribeErrorMessage}</p>
            ) : null}
            <CheckBox
              className="mt-3"
              id="stripeTOS"
              name="stripeTOS"
              label="Acepto los <a href='/politica-privacidad' target='_blank'>términos y condiciones</a>"
              hasLink={true}
            />
          </div>
        </div>

        <div id="copy" className="footer-public-two__section-copy">
          <small className="footer-public-two__section-copy-text">
            © {new Date().getFullYear()} Bookólicos
          </small>
        </div>
      </section>

      <div className="footer-public-two__section-cards">
        <img src="/images/medios-pago.svg" alt="cards" />
      </div>
    </>
  );
}

export default FooterPublic;
